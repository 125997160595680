import {
  IconButton,
  List,
  ListItem,
  Paper,
  Tooltip as MUITooltip,
  useTheme,
} from '@material-ui/core';
import { AddAPhoto } from '@material-ui/icons';
import saveAs from 'file-saver';
import type { VFC } from 'react';
import type { TooltipProps } from 'recharts';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import type { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import type { getCashflow } from 'src/calc/transactions/getCashflow';
import { currencyFormatter } from 'src/clients/tables/functions/makeGridColumns';
import type { ReturnBands } from 'src/clients/types';

type ReturnsNumbers = { [k in ReturnBands]: number };
type ReturnsNumbersYOY = { [k in `${ReturnBands}Yoy`]: number };

type BarGraphData = ReturnsNumbers & ReturnsNumbersYOY & { year: number };

export type BarGraphProps = Omit<CategoricalChartProps, 'data'> & {
  data: BarGraphData[];
};

export const BarGraph: VFC<BarGraphProps> = ({ data, ...props }) => {
  const [getPng, { ref }] = useCurrentPng();

  const {
    palette: { primary, secondary },
  } = useTheme();

  const lastValue = data.at(-1);

  if (!lastValue) return null;

  const legendColorMapper: Record<ReturnBands, string> = {
    avg: primary.light,
    max: primary.main,
    min: secondary.main,
  };

  const legendFormatter = (band: ReturnBands) => {
    const lastVal = lastValue[band];
    const shortenedLastVal = shortenCurrency(lastVal);
    const yoy = lastValue[`${band}Yoy`];
    const yoyPercent = (yoy * 100).toFixed(2);
    const color = legendColorMapper[band];
    const formatted = `${band}: ${shortenedLastVal} (${yoyPercent}%)`;

    return <Text style={{ color }}>{formatted}</Text>;
  };

  // png export
  const handleDownload = async () => {
    const png = await getPng();
    if (png) saveAs(png, 'myChart.png');
  };

  return (
    <div style={{ height: 300 }}>
      <MUITooltip title="Save as .png">
        <IconButton
          style={{ position: 'absolute', zIndex: 100 }}
          onClick={handleDownload}
        >
          <AddAPhoto />
        </IconButton>
      </MUITooltip>
      <ResponsiveContainer width={800} height={300}>
        <ComposedChart
          width={700}
          height={300}
          data={data}
          margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
          ref={ref}
          {...props}
        >
          <Legend
            width={200}
            layout="vertical"
            align="right"
            verticalAlign="top"
            formatter={legendFormatter}
          />
          {/* Highlight Final Values */}
          <CartesianGrid vertical={false} />
          <XAxis dataKey="year" height={80} />
          <YAxis
            domain={[0, 'auto']}
            allowDataOverflow={true}
            width={150}
            tickFormatter={shortenCurrency}
          />
          <Tooltip
            label="age"
            content={<CustomTooltip data={data} />}
            // following props are unneeded when using content above
            // formatter={shortenCurrency}
            // itemSorter={({ value }) => -(value as number)}
          />
          {/* <Legend /> */}
          <Line
            type="monotone"
            dataKey="max"
            //@ts-expect-error 600 does exist because we declared it in App.tsx
            stroke={primary[600]}
            strokeWidth={3}
            dot={false}
          />
          <Bar
            type="monotone"
            dataKey="avg"
            //@ts-expect-error 400 does exist because we declared it in App.tsx
            stroke={primary[400]}
            //@ts-expect-error 200 does exist because we declared it in App.tsx
            fill={primary[200]}
          />
          <Line
            type="monotone"
            dataKey="min"
            stroke={primary.main}
            strokeWidth={3}
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

type CustomTooltipProps = TooltipProps<number, keyof BarGraphData> &
  Pick<BarGraphProps, 'data'>;

const CustomTooltip: VFC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  data,
}) => {
  if (active && payload && payload.length) {
    const [avg, max, min] = payload;

    const yearData = data.find(({ year }) => year === parseInt(label ?? '0'));

    const sortedByValue = [avg, max, min].sort(
      (a, b) => (b.value ?? 0) - (a.value ?? 0)
    );

    return (
      <Paper elevation={3}>
        <List>
          <ListItem>{`Age: ${label}`}</ListItem>
          {sortedByValue.map(({ name, value }) => (
            <ListItem key={name}>{`${name}: ${shortenCurrency(value ?? 0)} (${(
              (yearData?.[`${name as ReturnBands}Yoy`] ?? 0) * 100
            ).toFixed(2)}%)`}</ListItem>
          ))}
        </List>
      </Paper>
    );
  }

  return null;
};

export const makeChartData = ({
  returns: { avg, max, min, expandedReturns },
}: ReturnType<typeof getCashflow>): BarGraphProps['data'] =>
  avg.cumulative.map((avg, year) => ({
    year,
    avg,
    max: max.cumulative[year],
    min: min.cumulative[year],
    avgYoy: expandedReturns.avg.averageRates.yoy[year],
    maxYoy: expandedReturns.max.averageRates.yoy[year],
    minYoy: expandedReturns.min.averageRates.yoy[year],
  }));

const shortenCurrency = (value: number) => {
  const isBillions = Math.abs(value) > 1000000000;
  const isMillions = Math.abs(value) > 1000000;
  const isThousands = Math.abs(value) > 10000;
  if (isBillions) return `${currencyFormatter(value / 1000000000, 0, 2)}B`;
  if (isMillions) return `${currencyFormatter(value / 1000000, 0, 2)}M`;
  if (isThousands) return `${currencyFormatter(value / 1000, 0, 0)}k`;
  return currencyFormatter(value, 0, 2);
};
